
import enums from '@/core/enums/enums';
import {defineComponent, reactive, inject, ref} from 'vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Api from "@/core/Api";
import router from "@/router";
import {IRegister} from "@/core/interfaces/IRegister"; // @ is an alias to /src


export default defineComponent({
  name: 'Register',
  components: {},
  setup(props, {attrs, slots, emit}) {
    const dataStore: any = inject('dataStore');

    const emailValid = ref(true);
    const passwordValid = ref(true);
    const password2Valid = ref(true);
    const emailReason = ref('Vigane email');
    const passwordReason = ref('Vigane parool');

    const state = reactive({
      email: '',
      password: '',
      password2: '',
    });

    const onRegister = async () => {
      emailValid.value = state.email.length > 0;
      passwordValid.value = state.password.length > 0;
      password2Valid.value = state.password2.length > 0;

      const passwordsMatch = state.password === state.password2;
      emailReason.value = 'Vigane parool';

      if (!passwordValid.value && !password2Valid.value) {
        passwordReason.value = 'Vigane parool';
      }
      if (!passwordsMatch) {
        passwordValid.value = password2Valid.value = false;
        passwordReason.value = 'Paroolid ei kattu';
      }

      if (!emailValid.value || !passwordsMatch || !passwordValid.value || !password2Valid.value) {
        return;
      }

      const registerDTO: IRegister = {
        email: state.email,
        password: state.password
      }

      await Api.register(registerDTO)
          .then((res: any) => {

            // navigate to login page
            router.push({path: '/login'});
          })
          .catch((err: any) => {
            emailValid.value = false;
            passwordValid.value = false;
            password2Valid.value = false;
            emailReason.value = 'Email on juba kasutuses või on vigane';
            passwordReason.value = 'Vigane parool';
          })
    }

    return {
      state,
      dataStore,
      enums,
      emailValid,
      passwordValid,
      password2Valid,
      onRegister,
      passwordReason,
      emailReason,
    }
  }
});
