<template>
  <div class="view register container-fluid" style="height: calc(100vh - 56px);">

    <div class="container-sm mt-5" style="max-width: 400px;">

      <div class="row" id="register-box" @keyup.enter="onRegister">
        <div class="col p-4" style="background: #ecf2a2">

          <h4 class="mb-3">Registreeri uus kasutaja</h4>

          <div class="form-floating mb-5">
            <input type="email" class="form-control" id="floatingInput" placeholder="name@example.com"
                   v-model="state.email" :class="{ 'is-invalid': !emailValid}">
            <label for="floatingInput">Email</label>
            <div class="invalid-feedback">{{ emailReason }}</div>
          </div>

          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="floatingPassword" placeholder="Password"
                   v-model="state.password" :class="{ 'is-invalid': !passwordValid}">
            <label for="floatingPassword">Parool</label>
            <div class="invalid-feedback">{{ passwordReason }}</div>
          </div>

          <div class="form-floating mb-3">
            <input type="password" class="form-control" id="floatingPassword2" placeholder="Password"
                   v-model="state.password2" :class="{ 'is-invalid': !password2Valid}">
            <label for="floatingPassword2">Parool uuesti</label>
            <div class="invalid-feedback">{{ passwordReason }}</div>
          </div>

          <button type="submit" class="btn btn-outline-secondary mb-3" @click="onRegister">Loo konto</button>

        </div>
      </div>

    </div>

  </div>
</template>

<script lang="ts">
import enums from '@/core/enums/enums';
import {defineComponent, reactive, inject, ref} from 'vue';
import {mapActions, mapGetters, mapMutations, mapState} from "vuex";
import Api from "@/core/Api";
import router from "@/router";
import {IRegister} from "@/core/interfaces/IRegister"; // @ is an alias to /src


export default defineComponent({
  name: 'Register',
  components: {},
  setup(props, {attrs, slots, emit}) {
    const dataStore: any = inject('dataStore');

    const emailValid = ref(true);
    const passwordValid = ref(true);
    const password2Valid = ref(true);
    const emailReason = ref('Vigane email');
    const passwordReason = ref('Vigane parool');

    const state = reactive({
      email: '',
      password: '',
      password2: '',
    });

    const onRegister = async () => {
      emailValid.value = state.email.length > 0;
      passwordValid.value = state.password.length > 0;
      password2Valid.value = state.password2.length > 0;

      const passwordsMatch = state.password === state.password2;
      emailReason.value = 'Vigane parool';

      if (!passwordValid.value && !password2Valid.value) {
        passwordReason.value = 'Vigane parool';
      }
      if (!passwordsMatch) {
        passwordValid.value = password2Valid.value = false;
        passwordReason.value = 'Paroolid ei kattu';
      }

      if (!emailValid.value || !passwordsMatch || !passwordValid.value || !password2Valid.value) {
        return;
      }

      const registerDTO: IRegister = {
        email: state.email,
        password: state.password
      }

      await Api.register(registerDTO)
          .then((res: any) => {

            // navigate to login page
            router.push({path: '/login'});
          })
          .catch((err: any) => {
            emailValid.value = false;
            passwordValid.value = false;
            password2Valid.value = false;
            emailReason.value = 'Email on juba kasutuses või on vigane';
            passwordReason.value = 'Vigane parool';
          })
    }

    return {
      state,
      dataStore,
      enums,
      emailValid,
      passwordValid,
      password2Valid,
      onRegister,
      passwordReason,
      emailReason,
    }
  }
});
</script>

<style scoped lang="scss">

#register-box {
  box-shadow: 2px 2px 10px 3px rgba(0, 0, 0, 0.5);
}

</style>
